<template>
    <dashboard-layout>
        <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="fans"
            :items-per-page="15"
            class="elevation-1"
        >
            <template v-slot:item.name="{ item }">
                <router-link :to="{name: 'admin.fans.show', params: {id: item.id}}">
                    {{ item.name }}
                </router-link>
            </template>
            <template v-slot:item.created_at="{ item }">
                {{ $date(item.created_at).format('MMM DD, YYYY HH:mm') }}
            </template>
        </v-data-table>
    </dashboard-layout>
</template>

<script>
import Fan from "@/models/Fan";
import DashboardLayout from "@/layouts/DashboardLayout";

export default {
    name: "users-index",
    components: {DashboardLayout},
    data: function () {
        return {
            loading: false,
            fans: [],
            headers: [
                {
                    text: '# ID',
                    align: 'start',
                    sortable: false,
                    value: 'id',
                },
                {text: 'Name', value: 'name'},
                {text: 'Email', value: 'email'},
                {text: 'Created at', value: 'created_at'},
            ],
        }
    },
    async mounted() {
        this.loading = true
        this.fans = await Fan.get()
        this.loading = false
    },
}
</script>

<style scoped>

</style>
